<template>
  <div class="card-header">
    <h5>Accounts</h5>
    <p class="text-sm">
      Here you can setup and manage your integration settings.
    </p>
  </div>
  <div class="card-body pt-0">
    <div class="d-flex">
      <argon-avatar class="width-48-px" :img="img1" alt="logo_slack" />
      <div class="my-auto ms-3">
        <div class="h-100">
          <h5 class="mb-0">Slack</h5>
          <a class="text-sm text-body" href="javascript:;">
            Show less
            <i class="fas fa-chevron-up text-xs ms-1" aria-hidden="true"></i>
          </a>
        </div>
      </div>
      <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
      <div class="my-auto">
        <argon-switch id="enable-slack" name="Enabled" checked />
      </div>
    </div>
    <div class="ps-5 pt-3 ms-3">
      <p class="mb-0 text-sm">
        You haven't added your Slack yet or you aren't authorized. Please add
        our Slack Bot to your account by clicking on
        <a href="javascript">here</a>. When you've added the bot, send your
        verification code that you have received.
      </p>
      <div
        class="d-sm-flex align-items-center bg-gray-100 border-radius-lg p-2 my-4"
      >
        <p class="text-sm font-weight-bold my-auto ps-sm-2">
          Verification Code
        </p>
        <argon-input
          id="verification-code"
          type="text"
          value="1172913"
          class="my-auto w-20 ms-sm-auto"
        />
      </div>
      <div class="d-sm-flex bg-gray-100 border-radius-lg p-2 my-4">
        <p class="text-sm font-weight-bold my-auto ps-sm-2">
          Connected account
        </p>
        <h6 class="text-sm ms-auto me-3 my-auto">hello@creative-tim.com</h6>
        <argon-button
          color="danger"
          variant="gradient"
          size="sm"
          class="my-sm-auto mt-2 mb-0"
          type="button"
          name="button"
          >Delete</argon-button
        >
      </div>
    </div>
    <hr class="horizontal dark" />
    <div class="d-flex">
      <argon-avatar class="width-48-px" :img="img2" alt="logo_spotify" />
      <div class="my-auto ms-3">
        <div class="h-100">
          <h5 class="mb-0">Spotify</h5>
          <p class="mb-0 text-sm">Music</p>
        </div>
      </div>
      <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
      <div class="my-auto">
        <argon-switch id="enable-spotify" name="Spotify" checked />
      </div>
    </div>
    <hr class="horizontal dark" />
    <div class="d-flex">
      <argon-avatar class="width-48-px" :img="img3" alt="logo_atlassian" />
      <div class="my-auto ms-3">
        <div class="h-100">
          <h5 class="mb-0">Atlassian</h5>
          <p class="mb-0 text-sm">Payment vendor</p>
        </div>
      </div>
      <p class="text-sm text-secondary ms-auto me-3 my-auto">Enabled</p>
      <div class="my-auto">
        <argon-switch id="enable-atlassian" name="Atlassian" checked />
      </div>
    </div>
    <hr class="horizontal dark" />
    <div class="d-flex">
      <argon-avatar class="width-48-px" :img="img4" alt="logo_asana" />
      <div class="my-auto ms-3">
        <div class="h-100">
          <h5 class="mb-0">Asana</h5>
          <p class="mb-0 text-sm">Organize your team</p>
        </div>
      </div>
      <div class="my-auto ms-auto">
        <argon-switch id="enable-asana" name="Asana" />
      </div>
    </div>
  </div>
</template>

<script>
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonInput from "@/components/ArgonInput.vue";

export default {
    name: "SocialAccount",
    components: {
        ArgonAvatar,
        ArgonSwitch,
        ArgonButton,
        ArgonInput
    },
    data() {
        return {
            img1: "https://cdn.mos.cms.futurecdn.net/SDDw7CnuoUGax6x9mTo7dd.jpg",
            img2: "https://upload.wikimedia.org/wikipedia/commons/thumb/1/19/Spotify_logo_without_text.svg/800px-Spotify_logo_without_text.svg.png",
            img3: "https://i.pinimg.com/originals/2f/ae/07/2fae07df207a6bf91bea8d511a75fdba.png",
            img4: "https://e7.pngegg.com/pngimages/516/695/png-clipart-asana-orange-logo-thumbnail-tech-companies-thumbnail.png",
        }
    }
}

</script>

<style></style>
