<template>
  <div class="card-header">
    <h5>Delete Account</h5>
    <p class="text-sm mb-0">
      Once you delete your account, there is no going back. Please be certain.
    </p>
  </div>
  <div class="card-body d-sm-flex pt-0">
    <div class="d-flex align-items-center mb-sm-0 mb-4">
      <div>
        <argon-switch id="delete-account" name="Delete Account" />
      </div>
      <div class="ms-2">
        <span class="text-dark font-weight-bold d-block text-sm">Confirm</span>
        <span class="text-xs d-block">I want to delete my account.</span>
      </div>
    </div>
    <argon-button
      color="secondary"
      variant="outline"
      class="mb-0 ms-auto"
      type="button"
      name="button"
      >Deactivate</argon-button
    >
    <argon-button
      color="danger"
      variant="gradient"
      class="mb-0 ms-2"
      type="button"
      name="button"
      >Delete Account</argon-button
    >
  </div>
</template>

<script>
import ArgonButton from "@/components/ArgonButton.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";

export default {
  name: "DeleteAccount",
  components: {
    ArgonButton,
    ArgonSwitch,
  },
};
</script>

<style></style>
