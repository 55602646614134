<template>
    <div class="card-header">
      <h5>Notifications</h5>
      <p class="text-sm">
        Choose how you receive notifications. These notification settings apply
        to the things you’re watching.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="table-responsive">
        <table class="table mb-0">
          <thead>
            <tr>
              <th class="ps-1" colspan="4">
                <p class="mb-0">Activity</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Email</p>
              </th>
              <th class="text-center">
                <p class="mb-0">Push</p>
              </th>
              <th class="text-center">
                <p class="mb-0">SMS</p>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Mentions</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user mentions you in a comment</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-email"
                  name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-push"
                  name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
              <td>
                <argon-switch
                  id="notify-sms"
                  name="Sms"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Comments</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user comments your item.</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-comment-email"
                  name="Email"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-comment-push"
                  name="Push"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-comment-sms"
                  name="SMS"
                  class="form-check form-switch mb-0 d-flex align-items-center justify-content-center"
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <span class="text-dark d-block text-sm">Follows</span>
                  <span class="text-xs font-weight-normal"
                    >Notify when another user follows you.</span
                  >
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-follow-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-follow-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-follow-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
            </tr>
            <tr>
              <td class="ps-1" colspan="4">
                <div class="my-auto">
                  <p class="text-sm mb-0">Log in from a new device</p>
                </div>
              </td>
              <td>
                <argon-switch
                  id="notify-login-email"
                  name="Email"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-login-push"
                  name="Push"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
              <td>
                <argon-switch
                  id="notify-login-sms"
                  name="SMS"
                  class="mb-0 d-flex align-items-center justify-content-center"
                  checked
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
</template>

<script>
import ArgonSwitch from "@/components/ArgonSwitch.vue";

export default {
    name: "NotificationSettings",
    components: {
      ArgonSwitch,
    }
}
</script>

<style>
</style>