<template>
    <div class="card-header">
      <h5>Merchant Info</h5>
    </div>
    <div class="card-body pt-0">
      <form @submit.prevent="onSubmit2">
        <div class="row">
          <div class="col-6">
            <label class="form-label">Nama Toko</label>
            <input
              v-model="form2.merchant_name"
              type="text"
              class="form-control"
            />
          </div>
          <div class="col-6">
            <label class="form-label">Phone</label>
            <input v-model="form2.phone" type="text" class="form-control" />
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="form-label">Tampilkan Alamat di Resi (AWB)</label>
            <select v-model="form2.show_address_on_awb" class="form-select">
              <option
                v-for="select in selects.show_address_on_awbs"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
          <div class="col-6">
            <label class="form-label">Tampilkan Telepon di Resi (AWB)</label>
            <select v-model="form2.show_phone_on_awb" class="form-select">
              <option
                v-for="select in selects.show_phone_on_awbs"
                :key="select.value"
                :value="select.value"
              >
                {{ select.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-12 text-end">
            <argon-button
              color="dark"
              variant="gradient"
              class="my-4 mb-2"
              :loading="isLoading2"
              >Update Data</argon-button
            >
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  import Swal from "sweetalert2";
  
  import { ElLoading } from "element-plus";
  import ArgonButton from "@/components/ArgonButton.vue";
  
  export default {
    name: "MerchantInfo",
    components: {
      ArgonButton,
    },
    data() {
      return {
        urlmerchant_read: "/api/v1/member/mymerchant",
        urlmerchant_update: "/api/v1/member/mymerchant/update",
        selects: {
          show_address_on_awbs: [
            {
              value: 1,
              name: "Tampilkan",
            },
            {
              value: 0,
              name: "Sembunyikan",
            },
          ],
          show_phone_on_awbs: [
            {
              value: 1,
              name: "Tampilkan",
            },
            {
              value: 0,
              name: "Sembunyikan",
            },
          ],
        },
        isLoading2: false,
        myMerchant: [],
        form2: {
          merchant_name: "",
          address: "",
          phone: "",
          show_address_on_awb: "",
          show_phone_on_awb: "",
        },
      };
    },
    created() {
      this.getMyMerchant();
    },
    methods: {
      loadingService() {
        const loading = ElLoading.service({
          lock: true,
          text: "Loading",
          background: "rgba(0, 0, 0, 0.1)",
        });
  
        return loading;
      },
      
      async getMyMerchant() {
        this.loadingService();
        try {
          const response = await axios.get(this.urlmerchant_read, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          });
          this.myMerchant = response.data.data;
          this.formDataMyMerchant();
  
          this.loadingService().close();
        } catch (error) {
          console.log(error);
          this.loadingService().close();
          this.errorRedirect500(error);
        }
      },
      formDataMyMerchant() {
        if (this.myMerchant) {
          this.form2.merchant_name = this.myMerchant.merchant_name;
          this.form2.phone = this.myMerchant.phone;
          this.form2.show_address_on_awb = this.myMerchant.show_address_on_awb;
          this.form2.show_phone_on_awb = this.myMerchant.show_phone_on_awb;
        }
      },
      
      async onSubmit2() {
        this.isLoading2 = true;
        this.loadingService();
        try {
          const response = await axios({
            method: "post",
            url: this.urlmerchant_update,
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            data: this.form2,
          });
          //console.log(response.data);
          this.isLoading2 = false;
          this.loadingService().close();
          if (response.data.status == "error") {
            Swal.fire("Warning!", response.data.message, "warning");
          } else {
            Swal.fire({
              title: "Good job!",
              html: response.data.message,
              icon: "success",
              timer: 1500,
              showConfirmButton: false,
            });
          }
        } catch (error) {
          console.log(error);
          this.isLoading2 = false;
          this.loadingService().close();
          this.errorRedirect500(error);
        }
      },
      errorRedirect500(error) {
        if (error.response.status == 500) {
          setTimeout(() => {
            this.$router.push({ path: "/authentication/error/error500" });
          }, 2000);
        }
      },
    },
  };
  </script>
  