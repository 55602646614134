<template>
<div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">
        This is a list of devices that have logged into your account. Remove
        those that you do not recognize.
      </p>
    </div>
    <div class="card-body pt-0">
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <div class="my-auto ms-3">
          <div class="h-100">
            <p class="text-sm mb-1">Bucharest 68.133.163.201</p>
            <p class="mb-0 text-xs">Your current session</p>
          </div>
        </div>
        <argon-badge color="success" size="sm" class="my-auto ms-auto me-3"
          >Active</argon-badge
        >
        <p class="text-secondary text-sm my-auto me-3">EU</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-desktop text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Chrome on macOS</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex align-items-center">
        <div class="text-center w-5">
          <i class="fas fa-mobile text-lg opacity-6"></i>
        </div>
        <p class="my-auto ms-3">Safari on iPhone</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">US</p>
        <a
          href="javascript:;"
          class="text-primary text-sm icon-move-right my-auto"
        >
          See more
          <i class="fas fa-arrow-right text-xs ms-1" aria-hidden="true"></i>
        </a>
      </div>
    </div>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";

export default {
    name: "SessionLogs",
    components: {
        ArgonBadge
    }
}
</script>