<template>
  <div class="row justify-content-center align-items-center">
    <div class="col-sm-auto col-4 clickable-pict">
      <el-tooltip
        class="box-item"
        effect="dark"
        content="Click for update"
        placement="top-start"
      >
        <argon-avatar
          :img="imgProfilePict"
          alt="Default Profile Pict"
          size="xl"
          shadow="sm"
          border-radius="lg"
          @click.prevent="handleUpdateProfilePict"
        />
      </el-tooltip>
    </div>
    <div class="col-sm-auto col-8 my-auto">
      <div class="h-100">
        <h5 class="mb-1 font-weight-bolder">{{ myProfile.fullname }}</h5>
        <p class="mb-0 font-weight-bold text-sm">{{ userProfileName }}</p>
      </div>
    </div>
    <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
      <div v-show="false">
        <label class="form-check-label mb-0 me-2">
          <small id="profileVisibility">Switch to invisible</small>
        </label>
        <argon-switch
          id="switch-invisible"
          name="Switch to Invisible"
          checked
        />
      </div>
    </div>
  </div>
  <modal v-model:show="modals.classic">
    <div class="p-2">
      <GenUpload
        v-if="showUploadProfilePict"
        label="Upload Profile Pict"
        formatinfo="Upload file dokumen berformat JPG/PNG"
        sizeinfo="Maksimum ukuran 1MB."
        type="profile_pict"
        accept="image/jpeg, image/png, image/gif"
        :url_upload="urlUploadProfilePict"
        :url_read="urlReadProfilePict"
        pathfolder=""
        @uploadcallback="getUploadCallback"
        @successuploadcallback="handleSuccessUploadCallback"
      ></GenUpload>

      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click="modals.classic = false"
      >
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "@/components/Modal";
import ArgonAvatar from "@/components/ArgonAvatar.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import { ElTooltip, ElLoading } from "element-plus";

import GenUpload from "@/components/Generate/GenUpload.vue";

import img1 from "@/assets/img/team-1.jpg";
import img2 from "@/assets/img/team-2.jpg";
import img3 from "@/assets/img/team-3.jpg";
import img4 from "@/assets/img/team-4.jpg";
import img5 from "@/assets/img/team-5.jpg";

export default {
  name: "ProfileInfo",
  components: {
    ArgonAvatar,
    ArgonSwitch,
    ElTooltip,
    Modal,
    GenUpload,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlmyprofile: "/api/v1/member/myprofile",
      urlread: "/api/v1/member_document/profile_pict",
      urlUploadProfilePict: "/api/v1/member_document/profile_pict/upload",
      urlReadProfilePict: "/api/v1/member_document/profile_pict",
      basePath: axios.defaults.baseURL,
      profilePict: [],
      myProfile: [],
      img1,
      img2,
      img3,
      img4,
      img5,
      imgProfilePict: "",
      showUploadProfilePict: false,
      userProfileName: "",
    };
  },
  created() {
    this.getMyProfile();
    this.getProfilePict();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getMyProfile() {
      this.loadingService();
      try {
        const response = await axios.get(this.urlmyprofile, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.myProfile = response.data.data;
        if(this.myProfile.user_profile.name != undefined) {
            this.userProfileName = this.myProfile.user_profile.name;
        }
        
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getProfilePict() {
      try {
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.profilePict = response.data.data;
        if (this.profilePict == null) {
          this.imgProfilePict = this.img2;
          this.randomDefaultIMG();
        }else{
            this.imgProfilePict = this.basePath+this.profilePict.path+'/'+this.profilePict.filename;
        }
      } catch (error) {
        console.log(error);
      }
    },
    randomDefaultIMG() {
      let maxNumber = 5;
      let random = Math.random();

      // get last charac
      let lastNumber = String(random).slice(-1);
      let findNumber = Number(lastNumber);

      if (findNumber > maxNumber) {
        findNumber = 5;
      } else if (findNumber == 0) {
        findNumber = 1;
      }

      switch (findNumber) {
        case 1:
          this.imgProfilePict = img1;
          break;
        case 2:
          this.imgProfilePict = img2;
          break;
        case 3:
          this.imgProfilePict = img3;
          break;
        case 4:
          this.imgProfilePict = img4;
          break;
        case 5:
          this.imgProfilePict = img5;
          break;
      }
    },
    handleUpdateProfilePict() {
      this.modals.classic = true;
      this.showUploadProfilePict = true;
    },
    handleSuccessUploadCallback(values) {
        if(values == true) {
            this.getProfilePict();
        }
    },
    
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>

<style scoped>
.clickable-pict {
  cursor: pointer;
}
</style>
