<template>
    <div class="card-header d-flex">
      <h5 class="mb-0">Two-factor authentication</h5>
      <argon-badge color="success" class="ms-auto">Enabled</argon-badge>
    </div>
    <div class="card-body">
      <div class="d-flex">
        <p class="my-auto">Security keys</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          No Security Keys
        </p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Add</argon-button
        >
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">SMS number</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">+4012374423</p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Edit</argon-button
        >
      </div>
      <hr class="horizontal dark" />
      <div class="d-flex">
        <p class="my-auto">Authenticator app</p>
        <p class="text-secondary text-sm ms-auto my-auto me-3">
          Not Configured
        </p>
        <argon-button
          color="dark"
          variant="outline"
          size="sm"
          class="mb-0"
          type="button"
          >Set up</argon-button
        >
      </div>
    </div>
</template>

<script>
import ArgonBadge from "@/components/ArgonBadge.vue";
import ArgonButton from "@/components/ArgonButton.vue";
export default {
    name: "TwoFactorAuth",
    components: {
        ArgonBadge,
        ArgonButton,
    }
}

</script>

<style></style>