<template>
  <div class="card-header">
    <h5>Change Password</h5>
  </div>
  <div class="card-body pt-0">
    <form @submit.prevent="onSubmit">
      <label class="form-label">New password</label>
      <input v-model="form.password" type="password" class="form-control" />
      <label class="form-label">Confirm new password</label>
      <input
        v-model="form.confirm_password"
        type="password"
        class="form-control"
      />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">
        Please follow this guide for a strong password:
      </p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <div class="row mt-4">
        <div class="col-md-12 text-end">
          <argon-button
            color="danger"
            variant="gradient"
            class="my-4 mb-2"
            :loading="isLoading"
            >Change Password</argon-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { ElLoading } from "element-plus";

import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "ChangePassword",
  components: {
    ArgonButton,
  },
  data() {
    return {
      url_update: "/api/v1/member/change_password",
      isLoading: false,
      form: {
        password: "",
        confirm_password: "",
      },
    };
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async onSubmit() {
      this.isLoading = true;
      this.loadingService();
      try {
        const response = await axios({
          method: "post",
          url: this.url_update,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire({
            title: "Good job!",
            html: response.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>

<style></style>
