<template>
  <div id="profile" class="card card-body mt-4">
    <ProfileInfo />
  </div>
  <div id="basic-info" class="card mt-4">
    <BasicInfo />
  </div>
  <div id="merchant-info" class="card mt-4">
    <MerchantInfo />
  </div>
  <div id="payment-info" class="card mt-4">
    <PaymentInfo />
  </div>
  <div id="password" class="card mt-4">
    <ChangePassword />
  </div>
  <div v-show="false" id="2fa" class="card mt-4">
    <TwoFactorAuth />
  </div>
  <div v-show="false" id="accounts" class="card mt-4">
    <SocialAccount />
  </div>
  <div v-show="false" id="notifications" class="card mt-4">
    <NotificationSettings />
  </div>
  <div v-show="false" id="sessions" class="card mt-4">
    <SessionLogs />
  </div>
  <div v-show="false" id="delete" class="card mt-4">
    <DeleteAccount />
  </div>
</template>

<script>
import ProfileInfo from "@/components/ProfileInfo.vue";
import BasicInfo from "@/components/BasicInfo.vue";
import MerchantInfo from "@/components/MerchantInfo.vue";
import PaymentInfo from "@/components/PaymentInfo.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import TwoFactorAuth from "@/components/TwoFactorAuth.vue";
import SocialAccount from "@/components/SocialAccount.vue";
import NotificationSettings from "@/components/NotificationSettings.vue";
import SessionLogs from "@/components/SessionLogs.vue";
import DeleteAccount from "@/components/DeleteAccount.vue";

export default {
  name: "SideNavItem",
  components: {
    ProfileInfo,
    BasicInfo,
    MerchantInfo,
    PaymentInfo,
    ChangePassword,
    TwoFactorAuth,
    SocialAccount,
    NotificationSettings,
    SessionLogs,
    DeleteAccount,
  },
  data() {
    return {
      user_id: "",
    };
  },
};
</script>
