<template>
  <div class="card-header">
    <h5>Basic Info</h5>
  </div>
  <div class="card-body pt-0">
    <form @submit.prevent="onSubmit">
      <div class="row">
        <div class="col-6">
          <label class="form-label">Nama Lengkap</label>
          <input v-model="form.fullname" type="text" class="form-control" />
        </div>
        <div class="col-6">
          <label class="form-label">NIK</label>
          <input v-model="form.nik" type="text" class="form-control" />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4 col-4">
          <label class="form-label mt-2">Gender</label>
          <select v-model="form.gender" class="form-select" required>
            <option
              v-for="select in selects.genders"
              :key="select.value"
              :value="select.value"
            >
              {{ select.name }}
            </option>
          </select>
        </div>
        <div class="col-sm-4">
          <label class="form-label mt-2">Birth Date</label>
          <flat-pickr
            v-model="form.birthdate"
            :config="config"
            class="form-control"
            placeholder="Select date"
            name="date"
          >
          </flat-pickr>
        </div>
        <div class="col-sm-4 col-4">
          <label class="form-label">Email</label>
          <input
            v-model="form.email"
            type="text"
            class="form-control"
            disabled
          />
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label">Propinsi</label>
          <select
            v-model="form.province_id"
            class="form-select"
            required
            @change="handleSelectCity"
          >
            <option
              v-for="select in selects.provinces"
              :key="select.value"
              :value="select.value"
            >
              {{ select.name }}
            </option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Kota/Kabupaten</label>
          <select
            v-model="form.city_id"
            class="form-select"
            required
            @change="handleSelectDistrict"
          >
            <option
              v-for="select in selects.cities"
              :key="select.value"
              :value="select.value"
            >
              {{ select.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <label class="form-label">Kecamatan</label>
          <select
            v-model="form.district_id"
            class="form-select"
            required
            @change="handleSelectSubDistrict"
          >
            <option
              v-for="select in selects.districts"
              :key="select.value"
              :value="select.value"
            >
              {{ select.name }}
            </option>
          </select>
        </div>
        <div class="col-6">
          <label class="form-label">Kelurahan</label>
          <select v-model="form.subdistrict_id" class="form-select" required>
            <option
              v-for="select in selects.subdistricts"
              :key="select.value"
              :value="select.value"
            >
              {{ select.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 align-self-center">
          <label class="form-label mt-2">Alamat</label>
          <textarea
            v-model="form.address"
            class="form-control"
            style="height: 100px"
          ></textarea>
        </div>
        <div class="col-md-6">
          <label class="form-label">Kodepos</label>
          <input v-model="form.postalcode" type="text" class="form-control" />
          <label class="form-label">Handphone</label>
          <input v-model="form.phone" type="text" class="form-control" />
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-md-12 text-end">
          <argon-button
            color="primary"
            variant="gradient"
            class="my-4 mb-2"
            :loading="isLoading"
            >Update Data</argon-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Swal from "sweetalert2";

import { ElLoading } from "element-plus";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
  name: "BasicInfo",
  components: {
    flatPickr,
    ArgonButton,
  },
  data() {
    return {
      urlread: "/api/v1/member/myprofile",
      urlupdate: "/api/v1/member/myprofile/update",
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: "Y-m-d",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      selects: {
        genders: [
          {
            value: "Laki-laki",
            name: "Laki-laki",
          },
          {
            value: "Perempuan",
            name: "Perempuan",
          },
        ],
        provinces: [],
        cities: [],
        districts: [],
        subdistricts: [],
      },
      isLoading: false,
      myProfile: [],
      form: {
        fullname: "",
        gender: "",
        nik: "",
        birthdate: "",
        address: "",
        province_id: "",
        city_id: "",
        district_id: "",
        subdistrict_id: "",
        postalcode: "",
        phone: "",
      },
    };
  },
  created() {
    this.getProvinceSelect();
    this.getMyProfile();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getMyProfile() {
      this.loadingService();
      try {
        const response = await axios.get(this.urlread, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.myProfile = response.data.data;
        this.formDataMyProfile();

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    formDataMyProfile() {
      if (this.myProfile) {
        this.form.fullname = this.myProfile.fullname;
        this.form.nik = this.myProfile.nik;
        this.form.gender = this.myProfile.gender;
        this.form.birthdate = this.myProfile.birthdate;
        this.form.email = this.myProfile.email;
        this.form.province_id = this.myProfile.province_id;
        this.getCitySelect(this.form.province_id);
        this.form.city_id = this.myProfile.city_id;
        this.getDistrictSelect(this.myProfile.city_id);
        this.form.district_id = this.myProfile.district_id;
        this.getSubDistrictSelect(this.myProfile.district_id);
        this.form.subdistrict_id = this.myProfile.subdistrict_id;
        this.form.address = this.myProfile.address;
        this.form.postalcode = this.myProfile.postalcode;
        this.form.phone = this.myProfile.phone;
      }
    },
    
    async getProvinceSelect() {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/province/select", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.provinces = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getCitySelect(province_id) {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/city/select/" + province_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.cities = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getDistrictSelect(city_id) {
      this.loadingService();
      try {
        const response = await axios.get("api/v1/district/select/" + city_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.selects.districts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async getSubDistrictSelect(district_id) {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/subdistrict/select/" + district_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.selects.subdistricts = response.data;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    handleSelectCity() {
      this.getCitySelect(this.form.province_id);
    },
    handleSelectDistrict() {
      this.getDistrictSelect(this.form.city_id);
    },
    handleSelectSubDistrict() {
      this.getSubDistrictSelect(this.form.district_id);
    },
    async onSubmit() {
      this.isLoading = true;
      this.loadingService();
      try {
        const response = await axios({
          method: "put",
          url: this.urlupdate,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire({
            title: "Good job!",
            html: response.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
