<template>
  <div class="card-header">
    <h5>Payment Info</h5>
  </div>
  <div class="card-body pt-0">
    <form @submit.prevent="onSubmit">
      <label class="form-label">Nama Bank</label>
      <input
        v-model="form.bank_name"
        type="text"
        maxlength="100"
        class="form-control"
        required
      />
      <label class="form-label">Nama Pemilik</label>
      <input
        v-model="form.bank_account_name"
        type="text"
        maxlength="100"
        class="form-control"
        required
      />
      <label class="form-label">Nomor Rekening</label>
      <input
        v-model="form.bank_account_number"
        type="text"
        maxlength="100"
        class="form-control"
        required
      />
      <div class="row mt-4">
        <div class="col-md-12">
          <label class="form-label"
            >PIN TOKEN |
            <a
              href="#"
              @click.prevent="handleRequestPinToken"
              class="text-primary"
              >Click here for request PIN TOKEN</a
            ></label
          >
          <input
            v-model="form.pintoken"
            type="text"
            maxlength="20"
            class="form-control"
            style="width: 200px"
            required
          />
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-md-6 text-left">
          <i class="text-secondary" style="font-size: 12px"
            ><i class="fas fa-info-circle"></i> the last bank data was updated
            on: {{ myPayment.display_updated_at }}</i
          >
        </div>
        <div class="col-md-6 text-end">
          <argon-button
            color="dark"
            variant="gradient"
            class="my-4 mb-2"
            :loading="isLoading"
            >Update Data</argon-button
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import { ElLoading } from "element-plus";

import ArgonButton from "@/components/ArgonButton.vue";
export default {
  name: "PaymentInfo",
  components: {
    ArgonButton,
  },
  data() {
    return {
      url_read: "/api/v1/member/mypayment_info",
      url_update: "/api/v1/member/mypayment_info/update",
      urlRequestPinToken: "/api/v1/member/mypayment_info/request_pintoken",
      isLoading: false,
      form: {
        bank_name: "",
        bank_account_name: "",
        bank_account_number: "",
        pintoken: "",
      },
      myPayment: [],
    };
  },
  created() {
    this.getMyPayment();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getMyPayment() {
      this.loadingService();
      try {
        const response = await axios.get(this.url_read, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.myPayment = response.data.data;
        this.formDataMyPayment();

        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    formDataMyPayment() {
      if (this.myPayment != "") {
        this.form.bank_name = this.myPayment.bank_name;
        this.form.bank_account_name = this.myPayment.bank_account_name;
        this.form.bank_account_number = this.myPayment.bank_account_number;
      }
    },
    async onSubmit() {
      this.isLoading = true;
      this.loadingService();
      try {
        const response = await axios({
          method: "post",
          url: this.url_update,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        //console.log(response.data);
        this.isLoading = false;
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire({
            title: "Good job!",
            html: response.data.message,
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async handleRequestPinToken() {
      this.loadingService();
      try {
        const response = await axios({
          method: "get",
          url: this.urlRequestPinToken,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        //console.log(response.data);
        this.loadingService().close();
        if (response.data.status == "error") {
          Swal.fire("Warning!", response.data.message, "warning");
        } else {
          Swal.fire({
            title: "Pin Token Sent!",
            html: response.data.message,
            icon: "success",
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>

<style></style>
